import {isNil, isEqual} from 'lodash';
import i18n from '@/plugins/i18n';
import {definedAndNotBlankObj} from '@/utilities/object';
import {OrderSubStatus} from '@/constants/order';

/**
 * Adds a polarity sign to the given value
 *
 * @param {Object} numValue
 * @returns
 */
export function addPolaritySign(numValue) {
    let resultString = '';
    if (Number(parseToNumber(numValue)) >= 0) {
        resultString += '+';
    }

    resultString += numValue;
    return resultString;
}

/**
 * Formats a lens number
 *
 * @param {*} numValue
 * @param {*} decimalPlaces
 * @param {*} intPlaces
 * @returns
 */
export function formatLensNum(numValue, decimalPlaces, decimalSeparator = '.', intPlaces = 2) {
    if (isNil(numValue) || isNaN(numValue) || isEqual(numValue, '')) return numValue;

    let numString = formatNumber(Math.abs(numValue), decimalPlaces, decimalSeparator);

    if (intPlaces != 1) {
        if (Math.abs(Math.trunc(numValue)) < 10) {
            numString = '0' + numString;
        }
    }

    if (numValue >= 0) {
        numString = '+' + numString;
    } else {
        numString = '-' + numString;
    }

    return numString;
}

/**
 * Formats a number to the specified decimal precision
 *
 * @param {String} number the number to format
 * @param {Number} decimalPlaces the number of decimal places to round to
 * @returns the formatted number
 */
export function formatNumber(number, decimalPlaces, decimalSeparator = '.') {
    if (number === '' || number === null) {
        return '';
    }

    number = Number(
        Math.round(parseFloat(number + 'e' + decimalPlaces)) + 'e-' + decimalPlaces
    ).toFixed(decimalPlaces);

    if (decimalPlaces) {
        number = number?.replace('.', decimalSeparator);
    }

    return number;
}

/**
 * Formats the given value with a sign, fixed decimal precision, and padding
 *
 * @param {Number} value
 * @param {Number} fixed
 * @param {Number} padding
 * @returns the signed padded value
 */

export function formatWithPadding(
    value,
    decimalSeparator = '.',
    decimalPlaces = 2,
    padding = 5,
    signEnabled = true
) {
    let sign = '-';

    if (value > 0) {
        sign = '+';
    }

    if (!signEnabled) sign = '';

    let numString = formatNumber(Math.abs(value), decimalPlaces, decimalSeparator);
    return `${sign}${numString.padStart(padding, '0')}`;
}

export function formatInput(
    value,
    event,
    allowedSymbols = ['-', '+'],
    decimalPlaces = 2,
    maxLength = 6,
    decimalSeparator = '.'
) {
    let formatted = '';

    // Removing no number character
    // Removing extra negative sign
    // Removing extra dots
    formatted = value
        .replace(new RegExp(`[^+0-9${decimalSeparator}-]`, 'g'), '')
        .replace(decimalSeparator, 'x')
        .replace(new RegExp(`\\${decimalSeparator}`, 'g'), '')
        .replace('x', decimalSeparator)
        .replace(/(?!^)-/g, '')
        .replace(/(?!^)\+/g, '')
        .substring(0, maxLength);

    if (!allowedSymbols.includes('-')) {
        formatted = formatted.replace(/-/g, '');
    }

    if (!allowedSymbols.includes('+')) {
        formatted = formatted.replace(/\+/g, '');
    }

    // Formatting with decimal places if is a number
    const formattedNumber = Number(parseToNumber(String(formatted)));
    if (event instanceof FocusEvent && formatted && !isNaN(formattedNumber)) {
        const plusSign = allowedSymbols.includes('+') && formattedNumber > 0 ? '+' : '';
        formatted = plusSign + formatNumber(formattedNumber, decimalPlaces, decimalSeparator);
    }

    /** After the new value is set to the input, default browser behavior is moving the cursor at the end.
     * We need to ensure cursor keep its position and to achieve it we need to place that logic in the callback queue
     * since in the call stack we need to return the new value to allow the render queue move the cursor to the new position.
     * Note: Bootstrap-vue page knows about the necessity but do not offer a elegant way to solve it.
     * Go to https://bootstrap-vue.org/docs/components/form-input#formatter-support last note section
     * Only trigger curso positioning when event is type InputEvent, safari browser get freeze otherwise
     */
    if (event instanceof InputEvent) {
        const {
            target: {selectionEnd, selectionStart},
        } = event;
        setTimeout(() => {
            event.target.selectionStart = selectionStart;
            event.target.selectionEnd = selectionEnd;
        });
    }

    return formatted;
}

/**
 * Formats the gender display
 *
 * @param {Object} obj an object that may contain the 'gender' key
 * @returns the formatted gender
 */
export function genderHandling(obj) {
    let output = definedAndNotBlankObj(obj, 'gender', '—');

    switch (output.toLowerCase()) {
        case 'm':
            return i18n.i18next.t('genderMaleCode');
        case 'f':
            return i18n.i18next.t('genderFemaleCode');
        default:
            return '';
    }
}

/**
 * Formats a lens calculation string using data from the given object
 *
 * @param {Object} obj
 * @returns the formatted lens calculation string
 */
export function lensDescriptionObjToString(obj, decimalSeparator = '.') {
    let calcString = '';

    /* Old format
    if (obj.sphere != undefined) {calcString += obj.sphere.toString()}
    if (obj.cylinder != undefined) {calcString += ` D ${obj.cylinder.toString()}`}
    if (obj.axis != undefined) {calcString += ` @ ${obj.axis.toString()}`} 
    */

    if (!isNil(obj.sphere)) {
        calcString += addPolaritySign(formatNumber(obj.sphere, 2, decimalSeparator));
    }
    if (!isNil(obj.cylinder)) {
        calcString += ` ${addPolaritySign(formatNumber(obj.cylinder, 2, decimalSeparator))}`;
    }
    if (!isNil(obj.axis)) {
        calcString += ` x${padNumber(obj.axis === 180 ? 180 : obj.axis)}`;
    }

    return calcString;
}

/**
 * Formats the given names, in the order passed in, as a comma-separated string
 *
 * @returns the formatted name or the vernacular name placeholder
 */
export function namesFormatter() {
    const args = [...arguments];

    /* Formatting full name */
    const names = args.filter((name) => typeof name === 'string' && !!name);
    const fullName = [...names.slice(0, 1), names.slice(1).join(' ')]
        .filter((name) => !!name)
        .join(', ');

    return fullName || '';
}

/**
 * Pads the given number with zeros up to three places if it is not nil or an
 * empty string
 *
 * @param {Object} numValue
 * @returns the padded number value or the number value itself
 */
export function padNumber(numValue) {
    if (isNil(numValue) || isEqual(numValue, '')) return numValue;

    let numString = numValue.toString().padStart(3, 0);

    return numString;
}

/**
 * Formats the surgeon's id and name. Adds an '*' if the surgeon is inactive.
 *
 * @param {String} id the surgeon's id
 * @param {String} name the surgeon's name
 * @param {Boolean} active the surgeon's active status
 * @returns the formatted surgeon id and name
 */
export function surgeonIdNameFormat(id, name, active) {
    if (!id || !name) return i18n.i18next.t('noSurgeon');

    return `${id}: ${name}${!active ? ' *' : ''}`;
}

export function decimalSeparatorFormatter(value, decimalSeparator) {
    return value?.toString()?.replace(/\./g, decimalSeparator);
}

export function parseToNumber(value) {
    return value?.replace(new RegExp(`\\,`, 'g'), '.');
}

/**
 * Replaces the enclosures in the given text string with the given replacements.
 * If no enclosures are found, the given text string is returned as part of the output.
 *
 * @param {String} textWithEnclosures - the text that possibly has enclosures
 * @param {String} openingEnclosureReplacement - the text to replace the opening enclosure with
 * @param {String} closingEnclosureReplacement - the text to replace the closing enclosure with
 * @param {Regex} openingEnclosure - the regex to use to find the opening enclosure
 * @param {Regex} closingEnclosure - the regex to use to find the closing enclosure
 *
 * @returns {Object} an object containing the text without enclosures and
 *  whether enclosures were removed or not
 */
export function replaceEnclosures(
    textWithEnclosures,
    openingEnclosureReplacement = '',
    closingEnclosureReplacement = '',
    openingEnclosure = /[{][{]/gi,
    closingEnclosure = /[}][}]/gi
) {
    let textWithoutEnclosures = textWithEnclosures;
    let enclosuresReplaced = false;

    if (textWithoutEnclosures) {
        textWithoutEnclosures = textWithoutEnclosures.replaceAll(
            openingEnclosure,
            openingEnclosureReplacement
        );

        textWithoutEnclosures = textWithoutEnclosures.replaceAll(
            closingEnclosure,
            closingEnclosureReplacement
        );

        enclosuresReplaced = textWithEnclosures !== textWithoutEnclosures;
    }

    return {textWithoutEnclosures, enclosuresReplaced};
}

/**
 * Modifies a Reservation or Order status coming from the database.
 * "Consumed" should be returned as "Implanted"
 *
 * @param {*} value - the Reservation/Order status to format
 * @returns the status, properly formatted
 */
export function getReservationOrderStatus(value, {isConsignment = false} = {}) {
    switch (value) {
        case OrderSubStatus.CONSUMED:
            return this.t('consumed');
        case OrderSubStatus.RESERVED:
            return isConsignment ? this.t('inventory_Assigned') : value;
        default:
            return value;
    }
}
