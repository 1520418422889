export const ZoneCodeStorageKey = 'zone-code';

export const ZoneCodes = Object.freeze({
    US: 'US',
    OUS: 'OUS',
});

export const ZoneWithBothCodes = Object.freeze({
    ...ZoneCodes,
    BOTH: 'BOTH',
});
