const state = {
    activeUser: null,
};

const getters = {};

const mutations = {
    setActiveUser(state, user) {
        state.activeUser = user;
    },
};

const actions = {
    // Update the user data
    async createUser(_, userData) {
        let response;
        let activeCustomerId = _.rootGetters['user/activeCustomerId'];

        try {
            response = await this._vm.$http.put('users', {
                ...userData,
                activeCustomerId,
                ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            });

            if (response.data.error) {
                throw new Error('Create User- Error obj defined');
            }

            if (response.data.results === undefined) {
                throw new Error('Create User- Results undefined');
            }

            if (response.data.success !== true) {
                throw new Error('Create User- Success not true');
            }

            if (response.status !== 200) {
                throw new Error('Create User- Response not 200');
            }
        } catch (err) {
            throw new Error('Unable to Create User');
        }
    },
    async updateUser(_, userData) {
        let response;

        try {
            response = await this._vm.$http.post('users', userData);

            if (response.data.error) {
                throw new Error('Update User- Error obj defined');
            }

            if (response.data.results === undefined) {
                throw new Error('Update User- Results undefined');
            }

            if (response.data.success !== true) {
                throw new Error('Update User- Success not true');
            }

            if (response.status !== 200) {
                throw new Error('Update User- Response not 200');
            }

            /* For some reason this doesn't always work on the new
            // AWS deployments so commenting out.
            if (response.statusText !== 'OK') {
                throw new Error('Update User- Response statusText not OK');
            }*/
        } catch (err) {
            throw new Error(this.$t('unableToUpdateUser'));
        }
    },
    async getUser(_, userId) {
        try {
            let urlString = `users/${userId}`;
            let response;
            response = await this._vm.$http.get(urlString);
            _.commit('setActiveUser', response.data.results);
        } catch (err) {
            throw new Error('Unable to Get User');
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
