<template>
    <span v-if="$attrs.disabled" class="disabled-link"><slot></slot></span>
    <router-link v-else :to="toRoute" v-bind="$attrs" v-on="$listeners">
        <slot></slot>
    </router-link>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
    name: 'CustomRouterLink',
    props: {
        to: {
            type: [String, Object],
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            activeCustomerOcosNumberOrId: 'user/activeCustomerOcosNumberOrId',
        }),
        toRoute() {
            return {
                ...this.to,
                query: {...this.to.query, ['account-id']: this.activeCustomerOcosNumberOrId},
            };
        },
    },
};
</script>
<style lang="scss">
.disabled-link {
    pointer-events: none;
    color: gray;
    cursor: not-allowed;
}
</style>
