export const SortCategories = Object.freeze({
    ACTIVITY: 'updated',
    USER: 'name',
    EMAIL: 'email',
});

export const DecimalSeparators = Object.freeze(['.', ',']);

export const AcknowledgementLocalStorageKey = 'acknowledgement-made';

export const BUCodes = Object.freeze({
    US: 'US BU',
    CH: 'CH BU',
    JP: 'JP BU',
    ALL: 'ALL',
});
