import {ZoneCodeStorageKey} from '@/constants/zone.js';

//
// STATE
//
const state = () => ({
    currentZone: null,
});

//
// GETTERS
//
const getters = {
    currentZone: (state) => state.currentZone,
};

//
// MUTATIONS
//
const mutations = {
    // Set the current zone
    setCurrentZone(state, currentZone) {
        localStorage.setItem(ZoneCodeStorageKey, currentZone);
        state.currentZone = currentZone;
    },
    // Remove the current zone
    removeCurrentZone(state) {
        localStorage.removeItem(ZoneCodeStorageKey);
        state.currentZone = null;
    },
};

//
// ACTIONS
//
const actions = {
    setCurrentZone({commit}, setCurrentZone) {
        commit('setCurrentZone', setCurrentZone);
    },
};

//
// DEFAULT EXPORT
//
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
