const state = {
    loading: false,
    POnumb: '',
    selectedTotal: null,
};

const getters = {
    getPOnumb: (state) => state.POnumb,
    getSelectedTotal: (state) => state.selectedTotal,
};

const mutations = {
    setPOnumb(state, newPOnumb) {
        state.POnumb = newPOnumb;
    },
    setSelectedTotal(state, total) {
        state.selectedTotal = total;
    },
};

const actions = {
    async submit(_, payload) {
        const items = payload.items.filter((item) => item.quantity > 0);
        const {data} = await this._vm.$http.post('orders', {...payload, items});
        return data;
    },
    async update(_, {orderHeaderId, status}) {
        const {data} = await this._vm.$http.post(`orders/${orderHeaderId}`, {status});
        return data;
    },
    async getReturnReasons(_) {
        const {data} = await this._vm.$http.get('orders/returnreasons');
        return data;
    },
    async updateMultiple(_, payload) {
        const {data} = await this._vm.$http.put(`orders`, payload);
        return data;
    },
    async getBulkOrder(_, bulkOrder) {
        const {bulkOrderId, page, per_page} = bulkOrder;
        const {data, headers} = await this._vm.$http.get(
            `orders/bulkorder/${bulkOrderId}?page=${page}&per_page=${per_page}`
        );

        data.totalPages = headers['x-orion-totalpages'] || 0;

        return data;
    },
    async getBulkOrderDetailReservations(_, bulkOrderDetailId) {
        const {data} = await this._vm.$http.get(
            `orders/bulkorderdetailreservations/${bulkOrderDetailId}`
        );

        return data;
    },
    async updateSelectedTotal({commit}, total) {
        commit('setSelectedTotal', total);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
