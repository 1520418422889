//
// STATE
//
const state = () => ({
    log: null,
    canViewLog: false,
});

//
// GETTERS
//
const getters = {
    log: (state) => state.log,
    canViewLog: (state) => state.canViewLog,
};

//
// MUTATIONS
//
const mutations = {
    // Set the log
    setLog(state, log) {
        state.log = log;
    },

    setCanViewLog(state, boolVal) {
        state.canViewLog = boolVal;
    },
};

//
// ACTIONS
//
const actions = {
    // Load the log entries
    async fetchLog({commit}, currentPage = 1) {
        let urlString = `logging/logs?page=${currentPage}&per_page=100`;
        const log = (await this._vm.$http.get(urlString)).data.results;

        commit('setLog', log);
    },

    setLogViewPermission({commit}, boolVal) {
        commit('setCanViewLog', boolVal);
    },
};

//
// DEFAULT EXPORT
//
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
