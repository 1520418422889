import {mapMutations, mapGetters} from 'vuex';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

export default {
    data() {
        return {};
    },
    computed: {
        ...mapGetters('user', ['currentUser']),
        ...mapGetters('permissions', ['permissions']),
        PERMISSIONS() {
            return PERMISSIONS;
        },
        PERMISSIONS_VALUES() {
            return PERMISSIONS_VALUES;
        },
    },
    methods: {
        ...mapMutations({
            setSpinner: 'app/setSpinner',
        }),
        /**
         * Check a specific permission for a specific user
         * @param {object} permissionsToCheck - An object identifying the permissions and values
         */
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser?.accessPermissions ?? {}
            );
        },
        /* Call the Vuex method to fetch and commit the backend API info. */
        async fetchApiInfo() {
            await this.blockingRequest('apiversion/fetchApiVersionInfo');
        },

        /**
         * Dispatch an action, blocking user input with an overlay until the Promise is resolved
         * @param {string} action - A Vuex action
         * @param {Object} payload - payload object to dispatch the action with
         * @returns The return value from the action
         */
        async blockingRequest(action, payload) {
            let response;
            this.setSpinner(true);
            try {
                response = await this.$store.dispatch(action, payload);
            } catch (error) {
                this.$bvToast.toast(error?.errors?.join(' ') || this.t('somethingWentWrong'), {
                    title: error?.errorCode || this.t('warning'),
                    variant: 'warning',
                    solid: true,
                });
                throw error;
            } finally {
                this.setSpinner(false);
            }
            return response;
        },

        /**
         * Block user input until all the promises are resolved
         * @param {Promise[]} promises - An array of promises
         */
        async blockUntilAllSettled(promises) {
            this.setSpinner(true);
            return Promise.allSettled(promises).finally(() => {
                this.setSpinner(false);
            });
        },
        /**
         * Export a data file
         * @param {string} entity - A string identifying the resource to export
         * @param {string} fileName - A string to named the file to download
         * @param {object} params - An object to apply query string parameters to the request
         */
        async exportData(entity, fileName, params) {
            let response;
            this.setSpinner(true);
            try {
                const headers = {
                    'X-Orion-Time-Zone-Id': Intl.DateTimeFormat().resolvedOptions().timeZone,
                };
                response = await this.$http.get(`export/${entity}`, {params, headers});
                const type = response.headers['content-type'] || '';
                const anchor = document.createElement('a');
                anchor.href = URL.createObjectURL(new Blob([response.data], {type}));
                anchor.target = '_blank';
                anchor.download = `${fileName}.csv`;
                anchor.click();
            } catch (error) {
                this.$bvToast.toast(error?.errors?.join(' ') || this.t('somethingWentWrong'), {
                    title: error?.errorCode || this.t('warning'),
                    variant: 'warning',
                    solid: true,
                });
                throw error;
            } finally {
                this.setSpinner(false);
            }
            return response;
        },
        /**
         * Triggers a standard alert message for features that are not yet implemented.
         */
        notImplemented: function () {
            this.$bvToast.toast(this.t('toastMessage_NotImplemented'), {
                title: this.t('toastTitle_NotImplemented'),
                variant: 'warning',
                solid: true,
            });
        },
    },
    watch: {
        currentLanguageCode: function (value) {
            localStorage.currentLanguageCode = value;
        },
    },
};
