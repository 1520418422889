import * as NetworkUtils from '@/utilities/network-utils';

const actions = {
    async addRole({commit, getters}, {editMode, roleData}) {
        commit('setLoading', true);

        let currentEndpoint = NetworkUtils.getCurrentEndpoint('roles', getters);

        try {
            let response;

            if (editMode) {
                response = await this._vm.$http.put(currentEndpoint, roleData, {
                    cancelToken: NetworkUtils.addCancelTokenConnectionTimeout(
                        getters.requestTimeout ?? 30000
                    ),
                    timeout: getters.requestTimeout,
                });
            } else {
                response = await this._vm.$http.post(currentEndpoint, roleData, {
                    cancelToken: NetworkUtils.addCancelTokenConnectionTimeout(
                        getters.requestTimeout ?? 30000
                    ),
                    timeout: getters.requestTimeout,
                });
            }

            NetworkUtils.checkResponse(response, currentEndpoint);

            commit('setLoading', false);
        } catch (error) {
            NetworkUtils.alertAndRethrow(error, commit, currentEndpoint);
        }
    },
    async addUserRole({commit, getters}, {roleObj}) {
        commit('setLoading', true);

        let url = `users/${roleObj.userId}/role`;

        try {
            let response = await this._vm.$http.post(url, roleObj, {
                cancelToken: NetworkUtils.addCancelTokenConnectionTimeout(getters.requestTimeout),
                timeout: getters.requestTimeout,
            });

            NetworkUtils.checkResponse(response, url);

            commit('setLoading', false);
        } catch (error) {
            NetworkUtils.alertAndRethrow(error, commit, url);
        }
    },
};

export default {
    namespaced: true,
    actions,
};
