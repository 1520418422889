import {AddressTypes} from '../../constants/customer';
import i18n from '@/plugins/i18n';

const state = {
    addresses: [],
};

const getters = {
    /**
     * Gets the available shipping addresses. If an address is the temporary
     * shipping address its name is set to a constant and internationalized.
     *
     * @param {Object} state the Vuex state
     * @returns the shipping addresses
     */
    shippingAddresses(state) {
        return state.addresses.map((a) => {
            a.name =
                a.allowAddressEntry && a.addressType == AddressTypes.TEMP_SHIPPING
                    ? i18n.i18next.t('checkout_TemporaryShippingAddress')
                    : a.name;
            return a;
        });
    },
};

const mutations = {
    setAddresses(state, addresses) {
        state.addresses = addresses;
    },
};

const actions = {
    /**
     * Fetches the available addresses. Include the temporary shipping address
     * if includeTemp is true. The temporary shipping address is only available
     * in the US zone.
     *
     * @param {Object} object Vuex methods
     * @param {Boolean} includeTemp if true include the temporary shipping
     *   address, if false do not include the temporary shipping address
     */
    async fetchAddresses({commit, rootGetters}, includeTemp = false) {
        let activeCustomerId = rootGetters['user/activeCustomerId'];
        let url = `customers/${activeCustomerId}/addresses`;
        if (includeTemp) {
            url += '?temp=true';
        }
        const {
            data: {results},
        } = await this._vm.$http.get(url);
        commit('setAddresses', results);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
